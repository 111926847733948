import "@fontsource/exo-2"
import "@fontsource-variable/exo-2"
import "@fontsource/arvo"
import * as React from 'react'
import type { GatsbyBrowser } from "gatsby"
import { AnimatePresence } from 'framer-motion'
import { Global, css } from '@emotion/react'
import Layout from './src/components/layout'

// Styling
const globalCSS = css`
        body {
          // overflow: hidden;
          margin: 0;
          padding: 0;
          background-color: #080808;
          font-family: "Exo 2", sans-serif;
          font-weight: 400;
          font-size: min(max(1rem, 4vw), 16px);
        }
        @supports (font-variation-settings: normal) {
          body {
            font-family: "Exo 2";
            font-variation-settings: "wght" 400;
          }
        }
        h1, h2, h3, h4 {
          font-family: "Arvo", serif;
          font-weight: 400;
        }
    `

// Scroll restoration
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  // in ms
  const TRANSITION_DELAY = 600

  // routing via clicking on link, go to top
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
  }

  // browser's forward or back buttons
  else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0]
    window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
  }
  return false
}

// AP and Global styles never dismounts, to keep track of children and do exit animations
export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => (
    <Layout>
      <Global styles={globalCSS} />
      <AnimatePresence mode="wait">
      {element}
      </AnimatePresence>
    </Layout>
)