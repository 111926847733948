import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { throttle } from '../utils/fun'
import Footer from './footer'

// Spacing
const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100 * var(--vh));
`

const FlexSpacer = styled.div`
  flex: 1 1;
`

export default function Layout ({ children }) {
  useEffect(() => {
    const vhHackUnthrottled = () => {
        document.querySelector(':root').style
        .setProperty('--vh', window.innerHeight/100 + 'px')
    }
    const vhHack = throttle(500, vhHackUnthrottled)
    vhHack()
    window.addEventListener('resize', vhHack)
    return () => {
      window.removeEventListener('resize', vhHack)
    }
  }, [])

  return (
    <FlexContainer>
      {children}
      <FlexSpacer />
      <Footer />
    </FlexContainer>
  )
}