export function throttle(delay: number, fn: Function) {
    let isThrottled: boolean = false
  
    return (args: any) => {
      if (isThrottled) return
  
      isThrottled = true
      fn(args)
      setTimeout(() => {
        isThrottled = false
      }, delay)
    }
  }