exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-poetry-mdx-fields-slug-tsx-content-file-path-src-content-poetry-1-1400-rpm-mdx": () => import("./../../../src/pages/poetry/{mdx.fields__slug}.tsx?__contentFilePath=/vercel/path0/src/content/poetry/1-1400-rpm.mdx" /* webpackChunkName: "component---src-pages-poetry-mdx-fields-slug-tsx-content-file-path-src-content-poetry-1-1400-rpm-mdx" */),
  "component---src-pages-poetry-mdx-fields-slug-tsx-content-file-path-src-content-poetry-before-ever-after-md": () => import("./../../../src/pages/poetry/{mdx.fields__slug}.tsx?__contentFilePath=/vercel/path0/src/content/poetry/before-ever-after.md" /* webpackChunkName: "component---src-pages-poetry-mdx-fields-slug-tsx-content-file-path-src-content-poetry-before-ever-after-md" */),
  "component---src-pages-poetry-mdx-fields-slug-tsx-content-file-path-src-content-poetry-contemplations-on-crudity-md": () => import("./../../../src/pages/poetry/{mdx.fields__slug}.tsx?__contentFilePath=/vercel/path0/src/content/poetry/contemplations-on-crudity.md" /* webpackChunkName: "component---src-pages-poetry-mdx-fields-slug-tsx-content-file-path-src-content-poetry-contemplations-on-crudity-md" */),
  "component---src-pages-poetry-mdx-fields-slug-tsx-content-file-path-src-content-poetry-haiku-16-feb-2020-md": () => import("./../../../src/pages/poetry/{mdx.fields__slug}.tsx?__contentFilePath=/vercel/path0/src/content/poetry/haiku-16-feb-2020.md" /* webpackChunkName: "component---src-pages-poetry-mdx-fields-slug-tsx-content-file-path-src-content-poetry-haiku-16-feb-2020-md" */),
  "component---src-pages-poetry-mdx-fields-slug-tsx-content-file-path-src-content-poetry-haiku-24-oct-2020-md": () => import("./../../../src/pages/poetry/{mdx.fields__slug}.tsx?__contentFilePath=/vercel/path0/src/content/poetry/haiku-24-oct-2020.md" /* webpackChunkName: "component---src-pages-poetry-mdx-fields-slug-tsx-content-file-path-src-content-poetry-haiku-24-oct-2020-md" */),
  "component---src-pages-poetry-mdx-fields-slug-tsx-content-file-path-src-content-poetry-street-food-md": () => import("./../../../src/pages/poetry/{mdx.fields__slug}.tsx?__contentFilePath=/vercel/path0/src/content/poetry/street-food.md" /* webpackChunkName: "component---src-pages-poetry-mdx-fields-slug-tsx-content-file-path-src-content-poetry-street-food-md" */),
  "component---src-pages-poetry-tsx": () => import("./../../../src/pages/poetry.tsx" /* webpackChunkName: "component---src-pages-poetry-tsx" */)
}

