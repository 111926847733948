import * as React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { Global, css, keyframes } from '@emotion/react'
import { motion } from 'framer-motion'

const FooterWrap = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  opacity: 0;
`

const FooterSpacer = styled.div`
  flex: 1 1;
`

const FooterLink = styled(Link)`
  color: #d3d3d3;
  margin: 0 0.2em;
  text-decoration: none;
`

const FooterText = styled.p`
  color: #d3d3d3;
  margin: 0 0.2em;
  text-decoration: none;
`
interface LinkProp {
    to: string;
    text: string;
  }

const FooterLinkAnimated = ({ to, text }: LinkProp) => (
  <FooterLink to={to}>
    <motion.p whileHover={{ color: '#ffffff' }} whileTap={{ color: '#ffffff' }} style={{marginBottom: '0px'}}>
      {text}
    </motion.p>
  </FooterLink>
)

const Footer = () => (
  <FooterWrap animate={{opacity: 1}} transition={{ duration: 1, }}>
    <FooterLinkAnimated to='/' text='home' />
    <FooterLinkAnimated to='/poetry' text='poetry' />
    <FooterSpacer />
    <FooterText>by Ruida Ding.</FooterText>
  </FooterWrap>
)

export default Footer